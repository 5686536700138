import request from "@/utils/request";
// 接口：根据父级部门ID获取子部门信息
// 地址：http://${BASE_URL}/user_service/sysDept/{id}/list
export function sysDeptList(id) {
    return request({
      url: `/user-service/sysDept/list?id=${id}`,
      method: "get",
    });
  }
  
  // 接口：创建部门
  // 地址：http://${BASE_URL}/user-service/sysDept
  export function sysDept(data) {
    return request({
      url: `/user-service/sysDept`,
      method: "post",
      data
    });
  }
  
  // 接口：修改部门
  // 地址：http://${BASE_URL}/user-service/sysDept
  export function sysDeptPut (data) { 
    return request({
        url: `/user-service/sysDept`,
        method: 'put',
        data
    })
  }
  // 接口：删除部门
  // 地址：http://${BASE_URL}/user-service/SysDept/{id}
  export function SysDeptDel(id) {
    return request({
        url: `/user-service/sysDept/${id}`,
        method: 'delete'
    })
  }